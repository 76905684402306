import * as React from "react"
import { useContext } from 'react';
import { UserContext } from './usercontext';

function UserLogin(props) {

  const user = useContext(UserContext);

  const handleUser = (event) => {
    event.preventDefault();
  }

  return (
    <div className={props.className}>
      <form className="w-full sm:w-1/2" onSubmit={handleUser}>
        <label>
          Name
          <input
            type="text"
            className="mt-1 block w-full rounded-md bg-gray-100 dark:bg-slate-900 dark:text-slate-100 border-transparent focus:border-gray-500 focus:bg-white focus:dark:bg-dark-900 focus:ring-0"
            value={user.name}
            onChange={e => user.setName(e.target.value)}
          />
        </label>
        {/*<button type="submit">Send</button>*/}
      </form>
      <p className="my-2">currently logged in as: {user.name}</p>
    </div>
  )
}

export default UserLogin
