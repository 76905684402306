import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import Container from "../components/container"
import ColourPicker from "../components/colourpicker"
import Userlogin from "../components/userlogin"
import SEO from "../components/seo"
import ReturnUser from "../components/returnuser"
import ProductAPI from "../components/productapi"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <StaticImage
      src="../images/am-logo-bg.png"
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="McInnes Design Banner"
      loading="eager"
      layout="fullWidth"
      placeholder="blurred"
      style={{ marginBottom: `1.45rem` }}
    />
    <Container>
      <div className="flex content-center justify-center">
        <div className="font-serif dark:text-slate-100 text-center max-w-screen-sm">
          <h1 className="text-4xl mb-5 font-bold underline">
            McInnes Design
          </h1>
          <h2 className="text-xl mb-5">A personal portfolio using React, built on Gatsby.</h2>
        </div>
      </div>
      <p className="my-4">Work in progress, currently building out components - Stay tuned for more updates.</p>
      <h2 className="font-serif dark:text-slate-100 text-xl mt-10 mb-1">Storing 'Username' to persist across website</h2>
      <p className="my-4">
        React form component, using React Context. This allows it to be applied to different components without
        having to manually pass it through each layer of the component tree.
      </p>
      <p className="mb-4">
        Enter some text below, the header menu will maintain the text across all pages. Because text is stored as
        it's typed - there is no need for a submit button. All you have to do is remove the default behavour of the
        HTML form so it doesn't unnecessarily reload the page.
      </p>
      <Userlogin/>
      <h2 className="font-serif dark:text-slate-100 text-xl mt-10 mb-1">Storing component data - Colour Picker</h2>
      <p className="my-4">
        React form component, using React State. Form input is stored in the component and passed to a CSS variable.
        Submit the form without any input to select a random colour.
      </p>
      <ColourPicker/>
      <h2 className="font-serif dark:text-slate-100 text-xl mt-10 mb-1">API Fetch Test</h2>
      <p className="my-4">
        Gatsby allows data to be retrieved either at build time or render time. This is acheived using the JS Fetch API.
        For build time data, Node and GraphQL is required. For render time, the standard Fetch API can be used.
      </p>
      <ProductAPI/>
      <p className="my-4">
        Feel free to check out my other site for more of my work:
        <OutboundLink href="https://acmcinnes.com.au/" className="hover:text-cyan-300">
          acmcinnes.com.au
        </OutboundLink>
      </p>
      <p className="my-4">Thanks <ReturnUser/>!</p>
    </Container>
  </>
)

export default IndexPage
