import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const ProductAPI = () => {
  const productData = useStaticQuery(graphql`
    query {
      allProduct {
        nodes {
          productName
          productImg
          productURL
          id
          SKU
          Brand
          DefaultPrice
          RRP
          imgFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)
  return (
    <section className="flex flex-row flex-wrap gap-4 justify-center w-full">
      {productData.allProduct.nodes.map(product => (
        <div className="w-full sm:w-72">
          <OutboundLink href={product.productURL}>
            <GatsbyImage image={product.imgFile?.childImageSharp?.gatsbyImageData} className="rounded-lg" alt={product.productName} />
          </OutboundLink>
          <div className="flex flex-col bg-slate-300 dark:bg-zinc-700 mt-2 p-4 rounded-lg">
            <p className="w-full text-xl font-semibold">
              <OutboundLink href={product.productURL}>
                {product.productName}
              </OutboundLink>
            </p>
            <div className="flex flex-row w-full">
              <p className="w-2/3 text-sm">
                {product.SKU}
              </p>
              <p className="w-1/3 text-right">
                {product.Brand}
              </p>
            </div>
            <p className="w-full">
              ${product.DefaultPrice}
              <span className="line-through ml-2">${product.RRP}</span>
            </p>
            <OutboundLink href={product.productURL} className="mt-1 bg-blue-500 hover:bg-blue-700 dark:bg-slate-500 dark:hover:bg-slate-700 text-white text-center font-bold py-2 px-4 rounded-md">
              See Details{` `}<FontAwesomeIcon icon={faExternalLinkAlt} />
            </OutboundLink>
          </div>
        </div>
      ))}
    </section>
  )
}
export default ProductAPI
